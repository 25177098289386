@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
//@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

@include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

html {
    font-size: 16px !important;
}

body {
    margin: 0;
    background-color: #e8e8e8;
    color: #444444;
    padding: 0;
}

$logoCellBg0: #B3B3FF;
$logoCellBg1: #0000A7;

#ieWarn {
    background-color: #a70000;
    color: yellow;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

#gdev-main {
    max-width: 1000px;
    margin: 0 auto;
    background-color: white;
    > div.push-footer {
        min-height: calc(100vh - 3.18rem);
    }
    nav {
        margin-bottom: 0.5rem;
        background-color: $logoCellBg1;
        ul > li {
            > a {
                color: white;
                font-size: 12px;
                font-weight: bold;
                &:hover {
                    color: $logoCellBg1;
                    background-color: $logoCellBg0;
                }
            }
            &.active > a {
                color: $logoCellBg1;
                background-color: $logoCellBg0;
            }
        }
    }
    h3, h4, h5 {
        color: $logoCellBg1;
        font-weight: bold;
    }
    div.logo-div {
        display: table;
        border-bottom: 4px solid $logoCellBg1;
        margin-left: 1em;
        padding-bottom: 1px;
        background-color: white;
        img {
            cursor: pointer;
        }
        .co-name {
            padding-top: 2px;
            font-size: 12px;
            font-weight: bold;
            color: $logoCellBg1;
            white-space: nowrap;
        }
    }
    footer {
        margin-top: 1rem;
        padding: 0.5rem 0.975rem;
        color: white;
        background-color: $logoCellBg1;
        font-size: 12px;
        text-align: right;
        white-space: nowrap;
    }
}


div.clr {
    clear: both;
}

.no-wrap {
    white-space: nowrap;
}

@import './components/logo';

