$logoCellSize: rem-calc(16, 16);
$logoBorderWidth: rem-calc(2, 16);

#logoReveal {
    display: table;
    table {
        width: auto;
        th {
            text-align: center;
            border-bottom: 1px solid #cccccc;
            padding: 0.25rem 0.625rem;
            &.bare {
                border-bottom: none;
            }
        }
        tr {
            padding: 0;
            td {
                font-size: 70%; 
                padding: 0;
                background-color: white;
                div.bit {
                    margin: 0;
                    padding: 0;
                    border: 0.0625rem solid white; 
                    width: $logoCellSize;
                    height: $logoCellSize;
                    &.cell0 {
                        background-color: $logoCellBg0;
                    }
                    &.cell1 {
                        background-color: $logoCellBg1;
                    }
                }
            }
        }
    }
}

caption.logoLegend {
    caption-side: bottom;
    padding: 0.25rem 0.625rem;
    font-weight: normal;
    font-size: 80%;
    span {
        display: inline-block;
        width: $logoCellSize;
        height: $logoCellSize;
        border-top: 0.125rem solid white;
        border-right: 0.125rem solid white;
        &.cell0 {
            background-color: $logoCellBg0;
        }
        &.cell1 {
            background-color: $logoCellBg1;
        }
    }
}

#logo-sizes {
    font-size: 85%;
    span {
        display: inline-block;
    }
}
